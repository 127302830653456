import { render, staticRenderFns } from "./InformationAdmin.vue?vue&type=template&id=8749a230&scoped=true"
import script from "./InformationAdmin.vue?vue&type=script&lang=js"
export * from "./InformationAdmin.vue?vue&type=script&lang=js"
import style0 from "./InformationAdmin.vue?vue&type=style&index=0&id=8749a230&prod&scoped=true&lang=scss"
import style1 from "./InformationAdmin.vue?vue&type=style&index=1&id=8749a230&prod&scoped=true&lang=css"
import style2 from "./InformationAdmin.vue?vue&type=style&index=2&id=8749a230&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8749a230",
  null
  
)

export default component.exports