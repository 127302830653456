<template>

  <div class="login-page">
    <div class="login-form">
      <div class="loginlogo"  @click="showPageHome()">
        <img src="/long1.png" class="pointer" style="width:80%">
      </div>


      <p class="textzc">还没有账号？ <a href="/register" class="switch">注册账号</a></p>

      <form @submit.prevent="handleLogin" >
        <div class="form-group">
          <input type="text" id="phone" v-model="phone" required placeholder="请输入手机号">
        </div>

        <div class="form-group">
          <input type="password" id="password" v-model="password" required placeholder="请输入密码">
        </div>

        <div class="form-group">
          <input type="text" id="captcha" v-model="captcha" required placeholder="请输入验证码"   style="display:inline-block;width:70% !important">
          <!--      	<img :src="codeUrl" style="display:inline-block;width:30% !important;height: 20px;">-->
          <img :src="codeUrl"  class="codeUrlClass" @click="refreshCode()">

        </div>

        <button type="submit" @click="handleLoginNew()" style="height:50px;font-size:18px;font-weight: 500;" class="loginButton">登录</button>
      </form>

      <p class="textzc bottom">忘记账号密码？ <a href="#" @click.prevent="forgetPassword" class="switch">找回密码</a></p>
    </div>
  </div>
</template>

<script>
import {getCodeImg, getInfo, login} from "@/api/login";

export default {
  data() {
    return {
      phone: '',
      password: '',
      captcha: '',
      rememberMe: false,
      isCaptchaLogin: false, // 切换到手机验证码登录的状态
      codeUrl:'',
      uuid:"",
      loginForm:{
        username:this.phone,
        password:this.password,
        captcha:this.captcha,
        uuid:this.uuid,

      }
    };
  },

  created() {
    this.getCode();
  },
  methods: {

    push(){
      console.log("1")
      this.$router.push({name: 'home'})
    },
    //刷新验证码
    refreshCode(){
      this.getCode();
    },



    showPageHome() {
      this.$router.push({ name: 'home' });
      // window.location.reload();
      // const targetPath = "/";
      // // 检查当前路由是否与目标路径相同
      // if (this.$route.path !== targetPath) {
      //   this.$router.push({ path: targetPath }).catch((err) => {
      //     if (err.name !== "NavigationDuplicated") {
      //       console.error(err);
      //     }
      //   });
      // } else {
      //   // 如果路径相同，可以选择刷新页面或其他操作
      //   window.location.reload(); // 可选
      // }
    },
    getCode() {

      getCodeImg().then(res => {
        this.captchaEnabled = res.captchaEnabled === undefined ? true : res.captchaEnabled;
        if (this.captchaEnabled) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
          this.uuid = res.uuid;
        }
      });
    },
    handleLogin(){

    },
    handleLoginNew() {
      const loginData = {
        phone: this.phone,
        password: this.password,
        captcha: this.captcha,
      };
      const userName =  this.phone
      const password =  this.password
      const captcha =  this.captcha
      const  uuid = this.uuid
      login(userName,password,captcha,uuid).then(response => {
        console.log("登录成功:", response);
        if (response.code === 200) {
          var token = response.token;
          sessionStorage.setItem("token", token);
          localStorage.setItem("token", token);
          var item = sessionStorage.getItem("token");
          console.log("token", item);
          //获取用户信息存下
          getInfo(token).then(response => {
            console.log("获取用户信息");
            sessionStorage.setItem("userId", response.data.user.userId);
            localStorage.setItem("userId", response.data.user.userId);
            var userId = sessionStorage.getItem("userId");
            console.log("userId", userId);
            var userJson = JSON.stringify(response.data.user);
            sessionStorage.setItem("userInfos", userJson);
            localStorage.setItem("userInfos", userJson);
            var userInfos = sessionStorage.getItem("userInfos");
            console.log(userInfos);
          }).catch(error => {
            console.error("请求失败", error);
          });
          this.$router.push({name: 'home'});
        }else {
          this.getCode();
        }

      }).catch(error => {
        console.error("登录失败:", error);
        this.getCode(); // 登录失败后刷新验证码
      });
    },
    switchToRegister() {
      // 切换到注册账号的逻辑
      console.log('切换到注册');
      // 你可以在这里跳转到注册页面
    },
    switchToCaptchaLogin() {
      // 切换到手机验证码登录的逻辑
      this.isCaptchaLogin = true;
      console.log('切换到手机验证码登录');
      // 你可以在这里修改表单以显示验证码登录相关的输入项
    },
    forgetPassword() {
      // 忘记密码，找回密码的逻辑
      console.log('找回密码');
      this.$router.push({name: 'changePassword'})
      // 你可以在这里跳转到找回密码页面
    }
  }
};
</script>

<style >
.login-page .form-group input{
  height: 50px;
  line-height:50px;
  opacity: 1;
  border-radius: 4px;
  border: 1px solid rgba(229, 230, 235, 1);

}
.login-page .form-group{
  display: flex;
  align-items: center;
}
.login-page {
  width: 100%;
  height: 100%;
  background-image: url('/public/background.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.layout{
  background-color:transparent !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  box-sizing: border-box;
  background-color:#FFFFFF;
  width: 90%;
  padding: 30px 45px ;
  margin: 5% auto;
  border-radius: .67rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.loginlogo{
  cursor: pointer;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}
.textstyle{
  font-size: 3rem;

  font-weight: 400;
  color: #333;
  margin-bottom: 4.08rem;
}
.textzc{
  display: flex;
  align-items: center;
  font-size: 1.08rem;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #333;
  margin-bottom: 1.92rem;
}
.bottom{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  font-size: 1.08rem;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #333;
}
.switch{
  color: #2782ff;
  text-decoration: underline;
  cursor: pointer;
}
input{
  box-sizing: border-box;
  background: #fff;
  width: 100%;
  padding: 1rem 1.25rem .92rem;
  border: 1px solid #aeaeae;
}
.loginButton:hover{
  background-color: #2238c2 !important;
}
.loginButton{
  background-color: #2238c2 !important;
}
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="password"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.loginButton {
  width: 100%;
  padding: 10px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}


.loginButton:hover {
  background-color: #3a9a6f;
}

p {
  text-align: center;
}
.codeUrlClass{
  flex: 1;
  height: 50px;
  /* margin-right: 20px; */
}
</style>


