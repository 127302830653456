<template>
  <div data-server-rendered="true" id="__nuxt" class="bannerBox">
    <!---->
    <div id="__layout">
      <div id="body">
        <div class="index_body">
          <div class="head_height">
            <div class="banner_box">
              <div class="banner page_width">
                <div class="ban_top">
                  <div class="logo" @click="showPageHome()">
                    <img src="/src/assets/img/ff1.png" alt/>
                  </div>
                  <div class="right flex">
                    <div class="no_login" v-if="!loginTrue" @click="loginUp()">
                      登录/注册
                    </div>
                    <div class="no_loginLeft" @click="myInformation" v-else>
                      <span>我的</span>
                    </div>
                    <div class="no_loginRight" v-if="loginTrue">
                      <span class="logout" @click="logout" v-if="loginTrue">退出</span>
                    </div>
                  </div>
                </div>
                <div class="ban_btn">
                  <div class="btn1" @click="existingEvidence()">我要存证</div>
                  <div style="width: 10%"></div>
                  <div class="btn2" @click="queryHashSslect()">我要查询</div>
                </div>
              </div>
            </div>
          </div>
          <div class="step_box" v-if="showPage">
            <div class="step page_width">
              <div class="step_top">操作<span>流程</span><span>（免费试用）</span></div>
              <div class="step_lab">第一时间上链，生成永久证书，不可篡改</div>
              <div class="step_itm">
                <div class="itm_box">
                  <div class="num">1</div>
                  <div class="span">注册认证</div>
                </div>
                <div class="line"></div>
                <div class="itm_box">
                  <div class="num">2</div>
                  <div class="span">上传作品</div>
                </div>
                <div class="line"></div>
                <div class="itm_box">
                  <div class="num">3</div>
                  <div class="span">提交审核</div>
                </div>
                <div class="line"></div>
                <div class="itm_box">
                  <div class="num">4</div>
                  <div class="span">生成证书</div>
                </div>
                <div class="line"></div>
                <div class="itm_box">
                  <div class="num">5</div>
                  <div class="span">查询核验</div>
                </div>
              </div>
            </div>
          </div>
          <div class="reason_box" v-if="showPage">
            <div class="reason page_width">
              <div class="tit">为什么要做<span>科技成果存证</span></div>
              <div class="cont">
                <div class="cont_itm">
                  <img
                      style="height: 100px; width: 100px;
margin-bottom: 5px;"
                      src="../assets/img/hua.png"
                  />
                  <div class="label">泰山链</div>
                  <div class="text">
                    采用区块链技术，第一时间让成果上链，区块链具有永久保存、不可篡改的特性。
                  </div>
                </div>
                <div class="cont_itm">
                  <img
                      style="height: 100px; width: 100px; margin-bottom: 5px"
                      src="../assets/img/dunpai.png"
                      alt
                  />
                  <div class="label">法律保障</div>
                  <div class="text">
                    与科技部门、公证处合作，通过凭证，证明成果在存证时实际存在、内容完整且未被更改。
                  </div>
                </div>
                <div class="cont_itm">
                  <img
                      style="
                      height: 100px; width: 100px;
                      margin-bottom: 5px;

                    "
                      src="../assets/img/computer.png"
                      alt
                  />
                  <div class="label">快速便捷</div>
                  <div class="text">
                    采用加密技术确保数据安全，保护隐私，在线一站式操作，仅需1-2分钟完成存证。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="exchange_box" v-if="showPage">
            <div class="exchange page_width">
              <div class="ex_left">
                <div class="tit">为什么选<span>泰山科技大市场</span></div>
                <div class="itm">
                  <img src="../assets/img/true.png" alt/>
                  <div>政府机构认定的科技成果转化交易单位。</div>
                </div>
                <div class="itm">
                  <img src="../assets/img/true.png" alt/>
                  <div>
                    泰山区块链作底层，对科技成果确权，永久保存，不可篡改。
                  </div>
                </div>
                <div class="itm">
                  <img src="../assets/img/true.png" alt/>
                  <div>政府科技部门、公证单位作背书，科技成果确权有保障。</div>
                </div>
                <div class="itm">
                  <img src="../assets/img/true.png" alt/>
                  <div>
                    存证服务的同时，能够为持有方开展查询、交易的全流程服务。
                  </div>
                </div>
              </div>
              <div class="ex_rig">
                <img src="../assets/img/zhengshu.png" alt/>
              </div>
            </div>
          </div>
          <div id="responsibilities" class="problem_box_inline" v-if="showPage">
            <div style="width: 1200px; margin-top: 100px">
              <div class="pro_tit">
                常见<span style="color: #2238c2">问题</span>
              </div>
              <div class="pro_lab">什么是科技成果存证？</div>
              <div class="pro_cont mb40">
                科技成果存证是利用区块链技术记录和证明科技成果归属和权益状况的过程。创作者在作品创作完成后，将原始文件和相关信息上传至区块链平台，一旦数据上链，就会形成一个不可篡改且带有时间戳的记录，这便构成了对版权的确权存证。
              </div>
              <div class="pro_lab">科技成果存证和版权备案有什么区别？</div>
              <div class="pro_cont mb40">
                科技成果存证是利用区块链技术对作品进行存证，证明作品在某个时间点的版权归属；而传统的版权备案则是向国家版权局提交申请，经过审查后获得正式的版权证书。两者在法律效力上相同，但数字版权存证更加便捷、快速、成本低。
              </div>
              <div class="pro_lab">科技成果存证有什么作用？</div>
              <div class="pro_cont mb40">
                创作者，防止别人盗用或抄袭。如果有人侵犯了您的版权，这个存证可以作为证据，帮您更加有效的维权
              </div>
              <div class="pro_lab">科技成果存证需要多长时间？</div>
              <div class="pro_cont mb40">
                系统正常运行的情况下，存证需要1-2分钟的时间
              </div>
              <div class="pro_lab">
                科技成果存证安全吗？会不会泄露我的信息？
              </div>
              <div class="pro_cont mb40">
                非常安全。存证平台使用先进的加密技术和区块链技术来保护您的作品和信息。不会把您的个人信息和作品内容泄露给任何人。
                科技成果存证后，我还能修改作品吗？
                一旦存证完成，原作品就不能再修改。因为存证就是为了证明该作品在某个时间点的状态，如果修改将无法证明，须再次进行存证。
              </div>
              <div class="pro_lab">科技成果存证的法律效力怎么样？</div>
              <div class="pro_cont mb40">
                存证平台是政府授权平台，提供可信时间凭证，存证内容可被司法机构认可，如果有人侵犯了您的版权，您向法院提起诉讼，存证信息将是有力证据。
              </div>

            </div>
          </div>
          <div class="form-container" style="margin-bottom: 90px" v-if="formShow">
            <!-- 步骤指示器 -->
            <div class="steps">
              <div class="step">
                <div class="step-number">1</div>
                <div class="step-text">注册认证</div>
              </div>
              <div class="step">
                <div class="step-number">2</div>
                <div class="step-text">上传作品</div>
              </div>
              <div class="step">
                <div class="step-number">3</div>
                <div class="step-text">提交审核</div>
              </div>
              <div class="step">
                <div class="step-number">4</div>
                <div class="step-text">生成证书</div>
              </div>
              <div class="step">
                <div class="step-number">5</div>
                <div class="step-text">查询核验</div>
              </div>
            </div>

            <!-- 提示信息 -->
            <div class="notice">
              <!--              提示说明：上上作品名称、分类、题材、作品上传公益法定字节需要填写完整，一经生成，申请作者不可一次性删除修改。(本系统认证不作任何产权保护用)-->
              提示说明：上传科技成果证明材料需要填写完整，委托专家评估可暂存，所有存证均需官方审核，需耐心等待，一经审核通过，立马上链，生成证书，申请作者不可删除修改。(本系统认证不作任何产权保护用)
            </div>

            <!-- 表单内容 -->
            <form>
              <div class="form-row">
                <label class="form-label required">成果名称:</label>
                <input type="text" class="form-input" placeholder="请输入成果名称" v-model="form.achievementName"/>
              </div>
              <div class="form-row">
                <label class="form-label required">持有单位/人:</label>
                <input type="text" class="form-input" placeholder="请输入持有单位/人(多位可用 '、'  隔开)"
                       v-model="form.holdingUnit"/>
              </div>
			  <div class="form-row">
			    <label class="form-label required">所属区县:</label>
			   <select id="areaCodeval" class="form-input" v-model="form.areaCodeval" @change="updateAreaCode(form.areaCodeval)">
				   <option value="" disabled selected>请选择持有单位/人所属区县</option>
				   <option v-for="item in areaCodeArray" :value="item.value">{{ item.name }}</option>
				 </select>
			  </div>
              <div class="form-row">
                <label class="form-label required">完成单位/人:</label>
                <input type="text" class="form-input" placeholder="请输入持有单位/人(多位可用 '、'  隔开)"
                       v-model="form.completionUnit"/>
              </div>
              <div class="form-row">
                <label class="form-label required">联系电话:</label>
                <input type="text" class="form-input" placeholder="请输入联系电话" v-model="form.phoneNumber"/>
              </div>
              <div class="form-row">
                <label class="form-label required">完成时间:</label>
                <el-date-picker class="dataclass"
                                v-model="form.completionTime"
                                type="date"
                                placeholder="选择日期">
                </el-date-picker>
              </div>
              <div class="rights-section">
                <label class="form-label required">技术领域:</label>
                <div class="rights-grid">
                  <div class="right-optionValue" v-for="right in technicalFieldList" :key="right">
                    <input type="radio" :value="right" v-model="form.technicalField">
                    <label>{{ right }}</label>
                  </div>
                </div>
              </div>
              <div class="rights-section">
                <label class="form-label required">价值评估:</label>
                <div class="rights-grid">
                  <div class="right-optionValue" v-for="right in rightsP" :key="right" >
                    <input
                        type="radio"
                        :value="right"
                        v-model="form.valueAssessment"
                        @change="updateEquityOwnership"
                    >
                    <label>
                      {{ rightText(right) }}
                      <!-- 仅在点击括号里的文字时跳转 -->
                      <span v-if="isExpertEvaluation(right)" @click="handleExpertClick" class="clickable">（委托专家评估）
          </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row" v-if="moneyShow">
                <label class="form-label required">金额(万元):</label>
                <input type="number" class="form-input" placeholder="请输入金额" v-model="form.money"/>
              </div>
              <div class="form-row" v-if="moneyFileShow">
                <label class="form-label required">上传评估文件:</label>
                <div class="file-upload">
                  <el-upload
                      class="upload-demo"
                      drag
                      :file-list="assessmentList"
                      action="http://kjcgcz.sdgtda.cn/dev-api/upload/uploadImage"
                      multiple
                      :on-success="handleUploadAssessment"
                      :on-remove="handleFileRemoveAssessment"
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">文件大小不得超过2GB</div>
                  </el-upload>
<!--                  <input type="file" id="fileInput" style="display: none" @change="uploadAssessment"/>-->
<!--                  <button-->
<!--                      type="button"-->
<!--                      class="upload-button"-->
<!--                      onclick="document.getElementById('fileInput').click()"-->
<!--                  >-->
<!--                    选择文件上传-->
<!--                  </button>-->
<!--                  <div class="preview-container">-->
<!--                    <div v-for="(file, index) in form.originalFilenameUrlName.split(',')"-->
<!--                         :key="index"-->
<!--                         class="file-item">-->
<!--                      <span style="color:red;font-size: 18px   ">{{ file }}</span>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>
              <div class="rights-section">
                <label class="form-label required">转化方式:</label>
                <div class="rights-grid">
                  <div class="right-option">
                    <input type="checkbox" id="all" name="rights" value="all" v-model="allSelected"
                           @change="toggleAll">
                    <label for="all">全选</label>
                  </div>
                  <div class="right-option" v-for="right in rights" :key="right">
                    <input type="checkbox" :value="right" v-model="selectedRights">
                    <label>{{ right }}</label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <label class="form-label required">成果介绍:</label>
                <el-input type="textarea"
                          show-word-limit
                          :maxlength="100"
                          class="form-input"
                          placeholder="请输入成果介绍(最多100个字)"
                          v-model="form.introductionAchievements"
                />
              </div>
              <div class="form-row">
                <label class="form-label required">核心技术:</label>
                <el-input type="textarea"
                          class="form-input"
                          placeholder="请输入核心技术(最多100个字)"
                          show-word-limit
                          :maxlength="100"
                          v-model="form.coreTechnology"/>
              </div>
              <div class="form-row">
                <label class="form-label required">市场前景:</label>
                <el-input type="textarea"
                          class="form-input"
                          placeholder="请输入市场前景(最多100个字)"
                          show-word-limit
                          :maxlength="100"
                          v-model="form.marketProspect"/>
              </div>
              <div class="form-row">
                <label class="form-label required">上传文件:</label>
                <div class="file-upload">
                  <div>
                    <el-upload
                        class="upload-demo"
                        drag
                        :file-list="formattedFileList"
                        action="http://kjcgcz.sdgtda.cn/dev-api/upload/uploadImage"
                        multiple
                        :on-success="handleUploadSuccess"
                        :on-remove="handleFileRemove"
                    >
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                      <div class="el-upload__tip" slot="tip">文件大小不得超过2GB</div>
                    </el-upload>
                  </div>
                </div>
              </div>
			  <div class="form-row">
			    <label class="form-label required">上传展示图片:</label>
			    <div class="file-upload">
			      <el-upload
			          class="upload-demo"
			          drag
			          :file-list="imgList"
			          action="http://kjcgcz.sdgtda.cn/dev-api/upload/uploadImage"
			          :on-success="handleUploadimg"
			          :on-remove="handleFileRemoveImg"
					  :limit="1"
					  :on-exceed="handleExceed"
			      >
			        <i class="el-icon-upload"></i>
			        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
			        <div class="el-upload__tip" slot="tip">文件大小不得超过5M</div>
			      </el-upload>
				  <div style="width:100%;text-align: center;">
				  <img :src="showimg" style="display:inline-block;text-align: center;width:20%"/>
				  </div>
			    </div>
			  </div>
              <div class="form-container">
                <!-- 文件类型说明 -->
                <div class="submitClass">
                  <!-- 提交按钮 -->
                  <div type="submit" class="submit-buttonLeft" @click="temporarilyStore()">暂存</div>
                  <div type="submit" class="submit-buttonRight" @click="homeviewSubmit()">立即存证</div>
                </div>
              </div>
            </form>
          </div>


          <div class="cha_xun" v-if="queryHash">
            <div class="tips" data-v-71571416>
              <!--              <img src="https://dc.ctex.cn/_nuxt/img/index_icon3.013f188.png" alt data-v-71571416 />-->
              <div class="tipMessage">
                证书核验<br data-v-71571416/>
                文件哈希值是区块链上存证的唯一标识，您可以核验您所持证据是否运用区块链技术已同步存储在泰山区块链。
              </div>
            </div>
            <div class="input_box" data-v-71571416>
              <div class="unified_numbering" data-v-71571416>
                <div class="filed" data-v-71571416>
                  <div class="querytext">证书编号:</div>
                </div>
                <div class="el-input" data-v-71571416>
                  <input
                      type="text"
                      v-model="certificateNo"
                      autocomplete="off"
                      placeholder="请输入证书编号"
                      class="el-input__inner"
                  />
                </div>
              </div>
              <div class="file_hash" data-v-71571416>
                <div class="filed" data-v-71571416>
                  <div class="querytext">文件哈希:</div>
                </div>
                <div class="el-input" data-v-71571416>
                  <input
                      type="text"
                      v-model="hashValue"
                      autocomplete="off"
                      placeholder="请输入文件哈希"
                      class="el-input__inner"
                  />
                </div>
              </div>
              <div class="query" @click="queryHashData()" data-v-71571416>查询核验</div>
            </div>
          </div>
          <div class="me" v-if="showInfo">
            <xinxi/>
          </div>
          <!---管理员走这个--->
          <div class="me" v-if="showInfoAdmin">
            <InformationAdmin/>
          </div>

          <div class="imgSh" v-if="imgShow">
            <div>
              <img class="imgSh"
                   style="height: 60%; width: 60%" :src="certificateUrl" alt/>
            </div>

          </div>


          <!---下面背景图 -->
          <div style="background: #252525">
            <img
                src="../assets/img/c02ddf2d50ba0a9c37c54f6b278d02a.png"
                alt
                class="foot_bg"
            />
            <div class="website">
              <div class="max_wid">
                <div class="max_lef">

                  <div class="labe2">版权所有：泰安市科学技术局</div>
                  <div class="labe2">
                    通讯地址：山东省泰安市省庄镇泰安市农高区花样年华景区内泰山农业科技孵化器大楼
                  </div>
                  <div class="labe2">主办单位：泰山科技大市场</div>
                </div>
                <div class="max_rig">
                  <!--              <div class="label">服务电话：</div>-->
                  <div class="labe2">联系方式：18953878448</div>
                  <div class="labe2">邮编：271000</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        :visible.sync="isExpertDialogVisible"
        title="委托专家评估"
        width="40%"

    >
      <el-form :model="expertForm" ref="expertForm">
        <el-form-item label="委托人单位">
        <el-input v-model="expertForm.principalNit" placeholder="请输入单位名称"/>
        </el-form-item>
        <el-form-item label="委托人姓名">
          <el-input v-model="expertForm.client" placeholder="请输入姓名"/>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="expertForm.contactInformation" placeholder="请输入联系电话"/>
        </el-form-item>
        <el-form-item label="其他备注">
          <el-input
              type="textarea"
              v-model="expertForm.notes"
              placeholder="请输入备注"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">

        <el-button class="subclass" type="primary" @click="submitExpertForm">提交</el-button>
        <el-button class="closeClass" type="warning" @click="closeExpertDialog">取消</el-button>
      </div>
    </el-dialog>

    <div>

    </div>
  </div>


</template>
<script>
import xinxi from "./xinxi.vue";
import InformationAdmin from "@/views/InformationAdmin.vue";
import {certificateQuery} from "@/api/certificate";
import {commissionAdd, uploadImg} from "@/api/http";
import {evidence} from "@/api/login";

export default {
  components: {xinxi,InformationAdmin},

  // computed: {
  //   login() {
  //     return login;
  //   },
  // },
  data() {
    return {
      expertForm: {
        notes: "",
        name: "",
        phone: "",
        contactInformation: "",
        principalNit: ""
      },
      showInfoAdmin:false,
      isExpertDialogVisible: false,
      moneyFileShow: false,
      moneyShow: false,
      certificateNo: '',
      hashValue: '',
      imgShow: false,
      loginTrue: false,
      showPage: true,
      formShow: false,
      queryHash: false,
      showInfo: false,
      certificateUrl: false,
      uploadFiles: "",
	  uploadImg: "",
	  showimg:"",
      form: {
        achievementName: "",//成果名称
        holdingUnit: "",//持有单位/人
        completionUnit: "",//完成单位/人
        phoneNumber: "", //联系电话
        completionTime: "",//完成时间
        introductionAchievements: "",//成果介绍
        coreTechnology: "",//核心技术
        marketProspect: "",//市场前景
        money: "",//金额
        //旧的
        depositSubject: "", // 存证主体
        isThirdParty: false, // 是否为第三方机构
        author: "", // 作者
        authorId: "", // 作者证件
        authorIdNumber: "", // 作者证件号码
        memberId: "", // 会员编号
        uploadFiles: "", // 上传文件
        depositName: "", // 存证名称
        depositType: "", // 存证类型
        equityOwnership: [], // 权益拥有
        originalFilenameUrl: "",//文件上传路径
        evaluationDocuments: "",//评估证书路径
        originalFilenameUrlName: "",//评估证书名
        //价值评估
        valueAssessment: "",
        //技术领域
        technicalField: "",
		uploadImg:"",
		areaCodeval:""
      },


      cache: {
        achievementName: "",//成果名称
        holdingUnit: "",//持有单位/人
        completionUnit: "",//完成单位/人
        phoneNumber: "", //联系电话
        completionTime: "",//完成时间
        introductionAchievements: "",//成果介绍
        coreTechnology: "",//核心技术
        marketProspect: "",//市场前景
        money: "",//金额
        //旧的
        depositSubject: "", // 存证主体
        isThirdParty: false, // 是否为第三方机构
        author: "", // 作者
        authorId: "", // 作者证件
        authorIdNumber: "", // 作者证件号码
        memberId: "", // 会员编号
        uploadFiles: "", // 上传文件
        depositName: "", // 存证名称
        depositType: "", // 存证类型
        equityOwnership: [], // 权益拥有
        originalFilenameUrl: "",//文件上传路径
        evaluationDocuments: "",//评估证书路径
        originalFilenameUrlName: "",//评估证书名
        //价值评估
        valueAssessment: "",
        //技术领域
        technicalField: "",
      },

      commission: {
        notes: "",
        name: "",
        phone: "",
        userId:""
      },


      commissionClean: {
        notes: "",
        name: "",
        phone: "",
        userId:""
      },

      isIndeterminate: false, // 全选状态控制
      checkedCities: [], // 选择的城市列表
      value: "",
      value1: "",
      value2: "",
      rights: [
        "自行实施转化",
        "科技成果转让",
        "科技成果许可",
        "合作实施转化",
        "科技成果作价投资",
        "其他",
      ],
      rightsP: [
        "自我估值",
        "专家估值（委托专家评估)"
      ],
      technicalFieldList: [
        "新一代信息技术产业",
        "高端装备制造产业",
        "新材料产业",
        "生物产业",
		"新能源汽车产业",
		"新能源产业",
		"节能环保产业",
		"数字创意产业",
		"相关服务业",
		"其他",
      ],
      allSelected: false,
      selectedRights:
          [],
	  areaCodeArray: [
		{value: 370902, name: "泰山区"},
		{value: 370911, name: "岱岳区"},
		{value: 370971, name: "高新区"},
		{value: 370983, name: "肥城市"},
		{value: 370982, name: "新泰市"},
		{value: 370921, name: "宁阳县"},
		{value: 370923, name: "东平县"},
		{value: -1, name: "其他地区"},
	  ],
    }
        ;
  },
  created() {
    //获取暂存数据
    this.queryLocalStorage();

    console.log(sessionStorage.getItem('token'))
    if (localStorage.getItem('token')) {
      this.loginTrue = true
      this.showPage = true
    } else {
      this.loginTrue = false
      // this.$router.push('/login')
    }


  },
  computed: {
    // 将 fileList 转换为 el-upload 组件需要的格式
    formattedFileList() {
      const fileList = this.form.originalFilenameUrl.split(',').map((file, index) => ({
        name: file,
        uid: index.toString(),
      }));
      console.log("formattedFileList data:", fileList);
      if (fileList[0].name ===''){
       // console.log("kong")
        return  []
      }else {
        return fileList;
      }
    },
    assessmentList(){
      const  assessmentList  = this.form.originalFilenameUrlName.split(',').map((file,index)=>({
        name:file,
        uid:index.toString(),
      }));
      if (assessmentList[0].name ===''){
        // console.log("kong")
        return  []
      }else {
        return assessmentList;
      }
    },
	imgList(){
	  const  imgList  = this.form.originalFilenameUrlName.split(',').map((file,index)=>({
	    name:file,
	    uid:index.toString(),
	  }));
	  if (imgList[0].name ===''){
	    console.log("1111111111111111111111111111111")
	    return  []
	  }else {
		console.log("222222222222222222222222222222222")
	    return imgList;
	  }
	},
  },


  methods: {
	// 更新 form.areaCode 的值
	updateAreaCode(selectElement) {
	  this.form.areaCodeval = selectElement;
	  console.log('Selected area code:', this.form.areaCode);
	},

    //判断是否为管理员
    isAdmin() {
      const userId = localStorage.getItem("userId");
      this.showAdmin = userId === '1';
    },

    handleFileRemoveAssessment(data){
      const fileArray = this.form.originalFilenameUrlName.split(',');
      // 2. 从数组中删除文件
      fileArray.splice(data.uid, 1); // 删除指定文件
      // 3. 更新 originalFilenameUrl 为删除后的字符串
      this.form.originalFilenameUrlName = fileArray.join(',');
    },
    //委托证明上传
    handleUploadAssessment(data){
      this.form.evaluationDocuments = this.form.evaluationDocuments ?
          this.form.evaluationDocuments + ',' + data.data.newName :
          data.data.newName

      //委托证明名字
      this.form.originalFilenameUrlName = this.form.originalFilenameUrlName ?
          this.form.originalFilenameUrlName + ',' + data.data.originalFilename :
          data.data.originalFilename
    },

    handleFileRemove(data){
      const fileArray = this.form.originalFilenameUrl.split(',');
      // 2. 从数组中删除文件
      const  file = this.form.uploadFiles.split(',');
      console.log("fileArray",fileArray);
      console.log("file",file);
        fileArray.splice(data.uid, 1); // 删除指定文件
      file.splice(data.uid,1);
      // 3. 更新 originalFilenameUrl 为删除后的字符串
      this.form.originalFilenameUrl = fileArray.join(',');
      this.form.uploadFiles = file.join(',');
    },


    //存证文件
    handleUploadSuccess(data){
      this.form.uploadFiles = this.form.uploadFiles ?
          this.form.uploadFiles + ',' + data.data.newName :
          data.data.newName
      //名字
      this.form.originalFilenameUrl = this.form.originalFilenameUrl ?
          this.form.originalFilenameUrl + ',' + data.data.originalFilename :
          data.data.originalFilename
    },
	//展示图片
	handleUploadimg(data){
		console.log("3333333333333333333333333333333");
		//console.log(data);
		this.showimg = data.data.newName
		this.form.uploadImg = data.data.newName
	},
	handleFileRemoveImg(data){
		console.log("444444444444444444444444444444");
		this.showimg = "";
	},
	handleExceed(files, fileList) {
	  // 当超过文件限制时的处理
	  this.$message.warning('只能上传一个图片，请先移除已有的图片再上传新的图片');
	},
    //委托弹窗提交
    submitExpertForm() {
      this.commission = this.expertForm;
      this.commission.userId = localStorage.getItem("userId")
      commissionAdd(this.commission).then(res=>{
        console.log("res",res);
        if (res.code === 200){
          this.$message({
            message: '您的委托已提交，请等待',
            type: 'success',
            duration: 3000,
            showClose: true,
            offset: 200
          })
        }
      })
      this.expertForm = this.commissionClean;
      this.isExpertDialogVisible = false
    },
    //弹窗取消操作
    closeExpertDialog() {
      this.isExpertDialogVisible = false
    },
    //获取暂存数据
    queryLocalStorage() {
      var item = localStorage.getItem("formData");
      if (item !== undefined && item != null) {
        this.form = JSON.parse(localStorage.getItem("formData"));
        const moneyShowStorage = localStorage.getItem("moneyShow");
        const moneyFileShowStorage = localStorage.getItem("moneyFileShow");
        this.selectedRights = localStorage.getItem("selectedRights").split(',')
        if (moneyShowStorage === 'true' && moneyFileShowStorage !== "true") {
          this.moneyShow = true
          this.moneyFileShow = false
        } else {
          this.moneyShow = true
          this.moneyFileShow = true
        }
      } else {

      }
    },

    isExpertEvaluation(right) {
      return right === "专家估值（委托专家评估)";
    },
    // 提取括号前的文本
    rightText(right) {
      return right.replace("（委托专家评估)", "");
    },
    // 点击括号里的文字时跳转
    handleExpertClick() {
      // this.$router.push('/login');
      this.isExpertDialogVisible = true
    },
    //暂存功能
    temporarilyStore() {
      localStorage.setItem('formData', JSON.stringify(this.form));
      localStorage.setItem("moneyShow", this.moneyShow);
      localStorage.setItem("moneyFileShow", this.moneyFileShow);
      localStorage.setItem("selectedRights", this.selectedRights)

      //清空列表
      this.form = this.cache;
      this.selectedRights = [];
      this.moneyFileShow = false;
      this.moneyShow = false;
      this.$message({
        message: '暂存成功',
        type: 'success',
        duration: 3000,
        showClose: true,
        offset: 200
      })
      this.form = this.cache;
      this.selectedRights = [];
    },


    getFileName(filePath) {
      // 使用正则表达式或 split 方法来处理路径
      // 这里假设路径分隔符是 '/' 或 '\\'
      let parts = filePath.split(/[\\/]/);
      return parts.pop();
    },
    //退出操作，清空token
    logout() {
      console.log("go");
      sessionStorage.clear();
      localStorage.clear();
      this.loginTrue = true;
      this.$router.push('/login')
      // var item = sessionStorage.getItem("token");

    },
    updateEquityOwnership(item) {
      this.value = item.srcElement.value;
      this.value1 = "自我估值";
      this.value2 = this.rightsP[1]
      if (this.value === this.value1) {
        this.moneyShow = true
        this.moneyFileShow = false
      } else if (this.value === this.value2) {
        this.moneyShow = true
        this.moneyFileShow = true
      }
      this.form.equityOwnership = this.selectedRights;
    },
    toggleAll() {
      if (this.allSelected) {
        // 如果全选被勾选，则选中所有权益
        this.selectedRights = this.rights.slice(); // 使用 slice() 来创建数组的一个副本
      } else {
        // 如果全选被取消，则清空已选中的权益
        this.selectedRights = [];
      }
    },
    homeviewSubmit() {
      var userId = localStorage.getItem("userId");
      const loginData = {
        depositSubject: this.form.depositSubject,
        author: this.form.author,
        authorIdNumber: this.form.authorIdNumber,
        membersIntroduced: this.form.membersIntroduced,
        memberId: this.form.memberId,
        uploadFiles: this.form.uploadFiles,
        depositName: this.form.depositName,
        depositType: this.form.depositType,
        equityOwnership: this.form.equityOwnership.toString(),
        auditStatus: this.form.auditStatus,
        userId: userId
      };
      this.form.equityOwnership = this.form.equityOwnership.toString()
      this.form.conversionMethod = this.selectedRights.toString()
      Object.assign(loginData, this.form);
      evidence(loginData).then(res => {
        if (res.code === 200) {
          this.$message({
            message: '您的申请已提交，请耐心等待审核',
            type: 'success',
            duration: 3000,
            showClose: true,
            offset: 200
          })
          //清空内容
          this.cleanForm();
		  this.showimg = "";
          localStorage.removeItem('formData');
        } else {
          this.$message.error('提交失败')
        }
      }).catch(err => {
        this.$message.error('提交异常')
      })
    },

    uploadAssessment(event) {
      console.log("1111111")
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];  // 获取单个文件
        // 生成预览URL
        this.imagePreview = URL.createObjectURL(file);
        this.imagePreview = URL.createObjectURL(file);

        // 调用上传接口
        this.Assessment(file);
      } else {
        this.imagePreview = null;
      }
    },
    async Assessment(file) {
      const response = await uploadImg(file).then(res => {
        if (res.code === 200) {
          console.log('上传成功:', res);
          console.log(res.data.newName);
          this.imgUrl = res.data.newName;

          this.form.evaluationDocuments = this.form.evaluationDocuments ?
              this.form.evaluationDocuments + ',' + res.data.newName :
              res.data.newName

          this.form.originalFilenameUrlName = this.form.originalFilenameUrlName ?
              this.form.originalFilenameUrlName + ',' + res.data.originalFilename :
              res.data.originalFilename
          console.log("this.originalFilenameUrlName", this.originalFilenameUrlName);
        } else {
          console.error('上传失败:', res);
        }
      });
    },
    //上传文件
    handleFileChange(event) {
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];  // 获取单个文件
        // 生成预览URL
        this.imagePreview = URL.createObjectURL(file);
        // 调用上传接口
        this.uploadFile(file);
      } else {
        this.imagePreview = null;
      }
    },


    //上传文件的接口
    async uploadFile(file) {
      const response = await uploadImg(file).then(res => {
        if (res.code === 200) {
          console.log('上传成功:', res);
          console.log(res.data.newName);
          this.imgUrl = res.data.newName;
          // this.uploadFiles = res.data.newName
          // this.form.uploadFiles = res.data.newName;
          const url = res.data.originalFilename;
          console.log("name", this.form.originalFilenameUrl)
          console.log("url", res.data.newName)
          this.form.uploadFiles = this.form.uploadFiles ?
              this.form.uploadFiles + ',' + res.data.newName :
              res.data.newName

          this.form.originalFilenameUrl = this.form.originalFilenameUrl ?
              this.form.originalFilenameUrl + ',' + res.data.originalFilename :
              res.data.originalFilename
        } else {
          console.error('上传失败:', res);
        }
      });


    },
    queryHashData() {
      const param = {
        certificateNo: this.certificateNo,
        hashValue: this.hashValue,
      }
      certificateQuery(param).then(res => {
        if (res.code === 200) {
          this.queryHash = false;
          this.showPage = false;
          this.formShow = false;
          this.showInfo = false;
          this.imgShow = true;
          this.certificateUrl = res.data.url
          this.showInfoAdmin =false;
        } else {
        }
      })

    },
    queryHashSslect() {
      this.queryHash = true;
      this.showPage = false;
      this.formShow = false;
      this.showInfo = false;
      this.imgShow = false;
      this.showInfoAdmin =false;
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.cities : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      const checkedCount = value.length;
      this.isIndeterminate =
          checkedCount > 0 && checkedCount < this.cities.length;
      this.equityOwnership = checkedCount === this.cities.length;
    },
    showPageHome() {
      this.showPage = true;
      this.formShow = false;
      this.queryHash = false;
      this.showInfo = false;
      this.imgShow = false;
      this.showInfoAdmin =false;
    },
    loginUp() {
      this.$router.push("/login");
    },

    existingEvidence() {
      this.queryLocalStorage()
      console.log(sessionStorage.getItem('token'))
      if (localStorage.getItem('token')) {
        this.loginTrue = true
        this.showPage = true
      } else {
        this.loginTrue = false
        this.$router.push('/login')
      }
      var item = localStorage.getItem("token");
      this.showPage = false;
      this.formShow = true;
      this.queryHash = false;
      this.showInfo = false;
      this.imgShow = false;
      this.showInfoAdmin =false;
      // }
    },
    myInformation() {

      console.log("1")
      const userId = localStorage.getItem("userId");
      console.log("userId",userId);
      if (userId ==='1'){
        this.showPage = false;
        this.formShow = false;
        this.queryHash = false;
        this.showInfo = false;
        this.imgShow = false;
        this.showInfoAdmin = true;
      }else {
        this.showPage = false;
        this.formShow = false;
        this.queryHash = false;
        this.showInfo = true;
        this.imgShow = false;
        this.showInfoAdmin = false;
      }

    },

    //清空表单
    cleanForm() {
      this.form = this.cache
      this.selectedRights = [];
      this.allSelected = false;
    },

    watch: {
      // 你也可以使用 watcher 来自动更新 form.equityOwnership，如果你希望它在 selectedRights 变化时自动更新
      selectedRights(newVal) {
        this.equityOwnership = newVal;
      }
    }
  },
};
</script>

<style scoped lang="scss">

::v-deep .subClass {
  color: #fff !important;
  background-color: #0077ff !important;
}

.closeClass {
  color: #fff;
  background-color: #ff4d4f;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clickable {
  color: #0077ff;
  cursor: pointer;
  //text-decoration: underline;
}

.bannerBox {
  width: 100%;
}

.layout {
  width: 100%;
}

::v-deep .layout {
  display: flex;
}

.banner_box {
  height: 500px;
}

.banner_box .ban_top .right .no_login {
  cursor: pointer;
  background: #e4e7ed;
  border-radius: 24px;
  font-size: 20px;
  color: #303133;
  line-height: 23px;
  padding: 12px 22px;
  margin-bottom: 30px;
}

.banner_box .ban_top .left img {
  width: 176px;
}

.img {
  max-width: 100%;
  border: none;
}

.banner_box .ban_top .left .div2 {
  position: relative;
  margin: 0 40px 0 80px;
  padding: 12px 20px;
  border-radius: 23px;
}

.banner_box {
  background-image: url('/src/assets/img/img1.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 24px 0 190px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-around;

}

.banner_box .ban_top .left .div1,
.banner_box .ban_top .left .div2 {
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  line-height: 20px;
}

.banner_box .ban_top .left .div1,
.banner_box .ban_top .left .div2 {
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  line-height: 20px;
}

.banner_box .ban_top {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.banner_box .ban_text {
  margin: 100px 0 43px;
  font-weight: 600;
  font-size: 54px;
  color: #fff;
  line-height: 71px;
  text-align: center;
}

.flex {
  display: flex;
  align-items: center;
  flex: none;
}

.banner_box .btn1,
.banner_box .btn2 {
  cursor: pointer;
  padding: 19px 90px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-right: 50px;
}

.ban_btn {
  margin-top: 410px;
}

.banner_box .ban_btn .btn1 {
  border-radius: 30px;
  border: 2px solid #fff;
  color: #fff;
}

.banner_box .ban_btn .btn2 {
  border-radius: 30px;
  background: #fff;
  color: #333;

}

.banner_box .ban_btn .btn1,
.banner_box .ban_btn .btn2 {
  cursor: pointer;
  padding: 19px 90px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-right: 50px;
}

.banner_box .ban_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step_box .step,
.step_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step_box {
  background: #f7f7f7;
  padding: 90px 0 100px;
}

.step_box .step .step_top {
  font-weight: 700;
  font-size: 32px;
  color: #333;
  line-height: 53px;
}

.step_box .step .step_lab {
  margin-top: 26px;
  font-weight: 400;
  font-size: 20px;
  color: #606266;
  line-height: 27px;
}

.step_box .step .step_itm {
  margin-top: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.page_width {
  width: 1200px;
  margin-top: 100px;
}

.step_box .step {
  flex-direction: column;
}

.step_box .step .step_itm .itm_box .num {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  color: #2238c2;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  border: 2px solid #2238c2;
}

.step_box .step .step_itm .line {
  margin-top: 27px;
  width: 15%;
  height: 2px;
  background: #2238c2;
}

.step_box .step .step_itm .line {
  margin-top: 40px;
  width: 10%;
  height: 2px;
  background: #2238c2;
}

.step_box .step .step_itm .itm_box .span {
  margin-top: 20px;
  font-weight: 500;
  font-size: 20px;
  color: #333;
}

.step_box .step .step_itm .itm_box .span {
  margin-top: 20px;
  font-weight: 500;
  font-size: 20px;
  color: #333;
}

.reason_box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 90px 0 120px;
}

.reason_box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 90px 0 120px;
}

.reason_box .reason .tit {
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: #333;
}

.reason_box .reason .tit {
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  color: #333;
}

.reason_box .reason .tit span {
  color: #2238c2;
}

.reason_box .reason .cont .cont_itm {
  margin-left: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 385px;
  height: 286px;
  padding: 62px 53px 0;
  box-sizing: border-box;
  background: #f5fbfc;
  border-radius: 12px;
  font-size: 16px;
}

.cont_itmDun {

}

.reason_box .reason .cont {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reason_box .reason .cont {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exchange_box .exchange .ex_left .tit {
  font-weight: 700;
  font-size: 32px;
  color: #333;
  margin-bottom: 50px;
}

.exchange_box .exchange .ex_left .tit span {
  color: #2238c2;
}

.exchange_box .exchange .ex_left {
  width: 75%;
}

.exchange_box .exchange .ex_left .itm img {
  width: 24px;
  height: 24px;
  margin-right: 14px;
  font-size: 16px;
}

img {
  max-width: 100%;
  border: none;
}

.exchange_box .exchange {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.exchange_box .exchange .ex_left .itm {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  font-size: 16px;
}

.exchange_box {
  padding: 84px 0 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../assets/img/lineImg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.exchange_box .exchange .ex_rig img {
  width: 349px;
}

.exchange_box .exchange .ex_rig img {
  width: 349px;
}

img {
  width: 100%;
  border: none;
}

.exchange_box .exchange {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.exchange_box .exchange {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.website {
  padding: 30px 0;
  background: #252525;
  justify-content: center;
}

.website .max_wid {
  max-width: 1200px;
  width: 100%;
  justify-content: space-around;
  color: #a8a8a8;
  background: #252525;
}

.website,
.website .max_wid {
  display: flex;
  align-items: center;
  line-height: 1.6;
}

.website .max_wid {
  max-width: 1200px;
  width: 100%;
  justify-content: space-around;
}

.website,
.website .max_wid {
  display: flex;
  align-items: center;
}

.website .max_wid {
  max-width: 1200px;
  width: 100%;
  justify-content: space-around;
}

.website,
.website .max_wid {
  display: flex;
  align-items: center;
}

.logo {
  cursor: pointer;
  background-image: url('/src/assets/img/logn.png');
  background-size: 450px 70px;
  width: 450px;
  height: 70px;
  margin-bottom: 25px
}

.problem_box {
  width: 100%;
  background: #fff;
  padding: 90px 0 131px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.problem_box_inline {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 131px;
}

.problem_box_inline .pro_tit span {
  color: #009eb8;
}

.problem_box_inline .pro_tit {
  font-weight: 700;
  font-size: 32px;
  color: #333;
  line-height: 53px;
  text-align: center;
  margin-bottom: 80px;
}

.problem_box_inline .pro_lab {
  font-weight: 700;
  margin-bottom: 13px;
  font-size: 20px;
  color: #333;
}

.problem_box_inline .pro_cont {
  font-weight: 400;
  font-size: 16px;
  color: #666;
  line-height: 28px;
}

.mb40 {
  margin-bottom: 40px;
}


.form-container {
  max-width: 1200px;
  margin: 90px auto 20px;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.steps {
  display: flex;
  justify-content: space-between;

  position: relative;
  padding: 0 40px;
  margin-top: 30px;
  margin-bottom: 60px;
}

.step {
  display: flex;
  align-items: center;
  z-index: 1;
}

.step-number {
  width: 24px;
  height: 24px;
  background: #1890ff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-size: 14px;
}

.step-text {
  font-size: 20px;
}

.step::after {
  content: "";
  display: inline-block;
  width: 127px;
  height: 1px;
  z-index: 0;
  margin-left: 6px;
}

.steps .step:last-child:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  background: #e8e8e8;
  z-index: 0;
  margin-left: 0;
}

.form-row {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-size: 20px;
}

.required::before {
  content: "*";
  color: #ff4d4f;
  margin-right: 4px;
}

.form-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 20px;
}

.radio-group {
  display: flex;
  gap: 20px;
}

.notice {
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 40px;
}

.file-upload {
  border: 1px dashed #d9d9d9;
  padding: 20px;
  text-align: center;
  background: #fafafa;
  border-radius: 4px;
}

.upload-button {
  background: #1890ff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.file-info {
  font-size: 20px;
  color: #666;
  margin-top: 12px;
}

.file-types {
  font-size: 20px;
  color: #666;
  margin-top: 8px;
}

.form-containerquery {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.required::before {
  content: "*";
  color: #ff4d4f;
  margin-right: 4px;
}

.file-types {
  font-size: 20px;
  color: #666;
  margin-bottom: 20px;
}

.certificate-type {
  border: 1px solid #e8e8e8;
  padding: 15px;
  margin-bottom: 20px;
}

.certificate-warning {
  color: #ff4d4f;
  font-size: 12px;
  margin-bottom: 10px;
}

.certificate-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-bottom: 20px;
}

.certificate-option {
  border: 1px solid #e8e8e8;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
}

.certificate-option:hover {
  border-color: #40a9ff;
}

.certificate-option.selected {
  border-color: #1890ff;
  background-color: #e6f7ff;
}

.certificate-option img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.rights-section {
  margin-bottom: 20px;
}

.rights-grid {
  width: 90%;
  margin: 0 auto;

  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.right-option {
  width: 25%;
  display: flex;
  align-items: center;

  font-size: 20px;
  margin-bottom: 15px;

  input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  label {
    color: #333;
  }
}

.agreement {
  display: flex;
  align-items: center;

  margin-bottom: 20px;
  justify-content: center;

  input {
    width: 16px;
    height: 16px;
  }
}

.agreement a {
  color: #1890ff;
  text-decoration: none;
}

.submit-buttonLeft {
  background: #1890ff;
  color: white;
  border: none;
  padding: 10px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  /* width: 100%; */
  width: 200px;
  text-align: center;
  margin-left: 25%;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.submit-buttonRight {
  background: #1890ff;
  color: white;
  border: none;
  padding: 10px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  /* width: 100%; */
  width: 200px;
  text-align: center;
  margin-left: 10%;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.submit-button {

}

.submit-button:hover {
  background: #40a9ff;
}

.notice {
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.cha_xun {
  padding: 3.5rem 18.33rem 10.42rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tips {
    font-size: 1.33rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #232323;
    line-height: 2.25rem;
  }
}

.cha_xun .tips {
  margin-top: 2.08rem;
  padding: 0.92rem 1.92rem 3.25rem;
  background: rgba(39, 130, 255, 0.1);
  border: 1px solid #2782ff;
  border-radius: 0.25rem;
  display: flex;
  align-items: baseline;
}

.cha_xun .tips div[data-v-71571416] {
  font-size: 1.33rem;
  font-weight: 400;
  color: #232323;
  line-height: 2.25rem;
  font-size: 20px;
}

.cha_xun .input_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cha_xun .file_hash[data-v-71571416],
.cha_xun .unified_numbering[data-v-71571416] {
  display: flex;
  align-items: center;
  margin-top: 4.25rem;
}

.cha_xun .file_hash .el-input[data-v-71571416] .el-input__inner,
.cha_xun .unified_numbering .el-input[data-v-71571416] .el-input__inner {
  border-radius: 0.25rem;
  font-size: 1.4rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.cha_xun .query[data-v-71571416] {
  cursor: pointer;
  margin-top: 5.67rem;
  padding: 1.17rem 10rem;
  display: inline-block;
  background: #2782ff;
  box-shadow: 0 0.3rem 3rem 0 rgba(29, 62, 187, 0.2);
  border-radius: 0.25rem;
  font-size: 1.67rem;
  font-weight: 700;
  color: #fff;
  line-height: 2rem;
}

.cha_xun .file_hash .filed[data-v-71571416],
.cha_xun .unified_numbering .filed[data-v-71571416] {
  display: flex;
  align-items: center;
}

.querytext {
  display: flex;
  width: 100px;
  font-size: 20px;
}

.me {
  max-width: 1200px;
  margin: 90px auto 90px;
  padding: 20px 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.imgSh {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.tipMessage {
  font-size: 20px;
}

.no_login {

}

.label {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}


::v-deep .labe2 {
  font-size: 16px;
}

.no_loginLeft {
  cursor: pointer;
  background: #e4e7ed;
  border-radius: 24px;
  font-size: 20px;
  color: #303133;
  line-height: 23px;
  padding: 12px 22px;
  margin-bottom: 30px;
}

.no_loginRight {
  cursor: pointer;
  background: #e4e7ed;
  border-radius: 24px;
  font-size: 20px;
  color: #303133;
  line-height: 23px;
  padding: 12px 22px;
  margin-bottom: 30px;
  margin-left: 10px;
}

.el-dropdownClass {
  width: 50%;
}


.dataclass {
  font-size: 20px;
}

.submitClass {
  display: flex;
}

.el-textarea .el-input__count {
  margin-bottom: 10px !important;
}

.right-optionValue {
  width: 30%;
  display: flex;
  align-items: center;

  font-size: 20px;
  margin-bottom: 15px;

  input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  label {
    color: #333;
  }
}
::v-deep .el-icon-close{
  font-size: 20px !important;
}
::v-deep .el-upload-list__item-name{
  font-size: 20px !important;
  color: #f56c6c !important;
}
::v-deep .el-upload-list__item .el-icon-upload-success{
  font-size: 20px !important;
}

.el-upload__tip{
  font-size: 20px;
}
::v-deep .el-upload-dragger{
  width: 600px !important;
}
</style>
